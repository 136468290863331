export default function loginRedirect() {
    const basename = process.env.REACT_APP_API_URL || 'https://app2.sharedcontacts.com/';
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let url = new URL('/authorize', basename);
    if (urlParams.has('intro') && urlParams.get('intro') === '1') {
        url = new URL('/authorize?intro=1', basename);
    }
    window.location = url;
    return [];
}
