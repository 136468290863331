import * as React from "react";
import {Toolbar} from "@mui/material";
import {useSelector} from "react-redux";

// project import
import Header from "../components/Header/Header";
import TrialOver from "../components/Trial/TrialOver";
import CheckingEligibility from "../components/Trial/CheckingEligibility";
import Congratulations from "../components/Trial/Congratulations";
import Sorry from "../components/Trial/Sorry";

export default function TrialPage({type}) {
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    if (type === 'trial_over') {
        return (
            <>
                <Header/>
                <Toolbar/>
                {domainInstallAlert && <Toolbar variant="dense"/>}
                <TrialOver/>
            </>
        )
    }
    if (type === 'checking_eligibility') {
        return (
            <>
                <Header/>
                <Toolbar/>
                {domainInstallAlert && <Toolbar variant="dense"/>}
                <CheckingEligibility/>
            </>
        )
    }
    if (type === 'congratulations') {
        return (
            <>
                <Header/>
                <Toolbar/>
                {domainInstallAlert && <Toolbar variant="dense"/>}
                <Congratulations/>
            </>
        )
    }

    if (type === 'sorry') {
        return (
            <>
                <Header/>
                <Toolbar/>
                {domainInstallAlert && <Toolbar variant="dense"/>}
                <Sorry/>
            </>
        )
    }

}
