import * as React from "react";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Dialog,
    Checkbox,
    DialogTitle,
    Typography, DialogContent, DialogActions, Button,
    Stack, IconButton, FormControlLabel, Grid,
} from "@mui/material";
import {CloseOutlined} from "@mui/icons-material";

// project import
import {openAddContactToLabelsDialog} from "../../store/reducers/dialogSlice";
import {selectGroups, addContactsToGroups} from "../../store/reducers/groupsSlice";
import store from "../../store/store";
import {addContactsToGroup} from "../../store/reducers/contactsSlice";

export default function AddContactsToLabelsDialog(selectedContacts) {
    const dispatch = useDispatch();
    const [selectedGroups, setSelectedGroups] = useState([]);
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    let groups = useSelector(selectGroups);
    groups = groups.filter(group => group.resource !== currentGroup.resource)
    const open = useSelector((state) => state.dialogsOpen.addContactsToLabels.value);
    const handleClose = () => {
        setSelectedGroups([]);
        dispatch(openAddContactToLabelsDialog(false))
    };
    const handleChange = (event) => {
        const resource = event.target.id;
        if (event.target.checked) {
            setSelectedGroups([...selectedGroups, resource]);
        } else {
            setSelectedGroups(selectedGroups.filter(item => item !== resource));
        }
    };
    const handleAddContactsToGroup = async () => {
        handleClose();
        const result = await store.dispatch(addContactsToGroups({
            groupsResources: selectedGroups,
            contactsResources: selectedContacts.rowSelectionModel
        }));
        for (let i = 0; i < result.payload.groups.length; i += 1) {
            store.dispatch(addContactsToGroup({
                ...result,
                payload: {...result.payload, group: result.payload.groups[i]}
            }));
        }
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    maxWidth: 444,
                    width: 444,
                    minWidth: 444,
                    backgroundColor: 'rgba(59, 125, 237, 1)',
                }
            }}
        >
            <DialogTitle sx={{backgroundColor: 'rgba(59, 125, 237, 1)'}}>
                <Grid container sx={{alignItems: 'center'}}>
                    <Grid item>
                        <Typography
                            variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Add contact</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: 'rgba(255, 255, 255, 0.54)',
                    '&:hover': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(0, 0, 0, 0.16)'
                    },
                }}
            >
                <CloseOutlined/>
            </IconButton>
            <DialogContent dividers sx={{border: 0, backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Stack direction={'column'} spacing={0}>
                    {groups.map((item) => (
                        <Stack key={item.resource} spacing={0}>
                            <FormControlLabel
                                key={item.resource}
                                control={
                                    <Checkbox
                                        id={item.resource}
                                        checked={item.selected}
                                        onChange={handleChange}
                                        color='primary'
                                    />
                                }
                                sx={{
                                    '.MuiFormControlLabel-label': {
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        fontSize: 16,
                                        fontFamily: `"Roboto"`,
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '150%',
                                        letterSpacing: '0.15px',
                                        width: '300px',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }
                                }}
                                label={item.name}
                            />
                        </Stack>
                    ))}
                </Stack>
            </DialogContent>
            <DialogActions sx={{backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                <Button onClick={handleClose}>
                    <Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography>
                </Button>
                <Button onClick={handleAddContactsToGroup} disabled={selectedGroups.length === 0}><Typography variant={'dialogButton'}>Add Contacts</Typography></Button>
            </DialogActions>
        </Dialog>
    )
}

