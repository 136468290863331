import {useState} from "react";
import {
    Box,
    Checkbox,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
    List,
    Stack, SvgIcon,
} from "@mui/material";
import {
    ShareOutlined,
    ModeEditOutlineOutlined,
    AddOutlined,
} from "@mui/icons-material";
import * as React from "react";
import {useSelector} from "react-redux";
import Marquee from 'react-double-marquee';

// project import
import store from "../../store/store";
import {setRowSelectionModel} from '../../store/reducers/contactsSlice';
import {
    checkOne,
    changeContacts,
    selectGroups,
    setGroupsToShare,
    setLabelToRename
} from "../../store/reducers/groupsSlice";
import {openAddNewLabelDialog, openRenameLabelDialog, openShareLabelsDialog} from "../../store/reducers/dialogSlice";
import NoLabelsFound from "./NoLabelsFound";
import {checkRole} from "../../services/checkRole";
import * as RolesConstants from "../../constants/roles";

export function Labels({drawerWidth}) {
    const groups = useSelector(selectGroups);
    const labelsFilter = useSelector((state) => state.groups.labelsFilter);
    const groupsInitialized = useSelector((state) => state.groups.groupsInitialized);
    const searchLabelsFilter = useSelector((state) => state.groups.searchLabelsFilter);
    if (!groupsInitialized) return null;
    const preparedGroups = groups.filter(group => group.resource !== 'contactGroups/myContacts');
    const groupsFiltered = getLabelsByFilter(preparedGroups, {
        labelsFilter,
        searchLabelsFilter
    });
    if (!groupsFiltered.length) {
        return <NoLabelsFound/>
    }
    return (
        <List>
            {
                groupsFiltered.map((group, index) => (
                    <GroupListItem key={index} group={group} drawerWidth={drawerWidth}/>
                ))
            }
            <CreateLabelSection drawerWidth={drawerWidth}/>
        </List>
    )
}

const GroupListItem = ({group, drawerWidth}) => {
    const [hover, setHover] = useState(false);
    const handleClickLabel = (group) => {
        store.dispatch(changeContacts({resource: group.resource}));
        store.dispatch(setRowSelectionModel([]));
    };
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const checkedLabels = useSelector((state) => state.groups.checkedLabels);
    const checkedGroupsSet = new Set(checkedLabels.map(label => label.group));
    const isChecked = checkedGroupsSet.has(group.resource);
    return (
        <ListItemButton
            disableRipple
            onClick={() => handleClickLabel(group)}
            key={group.resource}
            sx={{
                maxWidth: drawerWidth,
                backgroundColor: currentGroup.resource === group.resource && '#E8F0FE' || isChecked && 'rgba(0, 0, 0, 0.04)',
                borderRadius: '0px 20px 20px 0px',
                height: 40,
                '&:hover': {
                    backgroundColor: currentGroup.resource === group.resource && '#E8F0FE',
                },
            }}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        >
            <GroupIcon hover={hover} group={group} isChecked={isChecked}/>
            <GroupText hover={hover} group={group}/>
            <GroupCount hover={hover} group={group}/>
            <GroupSecondaryAction hover={hover} group={group}/>
        </ListItemButton>
    )
}

const GroupIcon = ({hover, group, isChecked}) => {
    const handleClickCheckBoxLabel = (event) => {
        event.stopPropagation();
        const status = event.target.checked;
        store.dispatch(checkOne({group: group.resource, status}));
    };
    return (
        <ListItemIcon
            sx={{
                marginRight: -2,
                marginLeft: '6px',
            }}
        >
            <Checkbox
                inputProps={{'aria-labelledby': group.id}}
                id={group.resource}
                disabled={!group.is_user_owner}
                checked={isChecked}
                onClick={handleClickCheckBoxLabel}
                sx={{
                    display: !(isChecked || hover) && 'none',
                    width: 24,
                    height: 24,
                    color: 'rgba(0, 0, 0, 0.6)',
                }}
            />
            <ShareStatusIcon group={group} hover={hover} isChecked={isChecked}/>
        </ListItemIcon>
    )
}

const GroupText = ({hover, group}) => {
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    return (
        <>
            <ListItemText
                sx={{
                    display: !hover && 'none'
                }}
                primary={
                    <div
                        style={{
                            width: 130,
                            whiteSpace: 'nowrap',
                            color: currentGroup.resource === group.resource && 'rgba(25, 103, 210, 1)',
                            display: !hover && 'none',
                            fontFamily: 'Open Sans',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '20px',
                        }}
                    >
                        <Marquee
                            delay={0}
                            direction={"left"}
                            children={group.name}
                            scrollWhen={"overflow"}
                        >
                            {group.name}
                        </Marquee>
                    </div>
                }
            />
            <ListItemText
                sx={{
                    display: hover && 'none',
                }}
                primary={
                    <Box
                        sx={{
                            width: 105,
                        }}
                    >
                        <Typography
                            sx={{
                                color: currentGroup.resource === group.resource && 'rgba(25, 103, 210, 1)',
                                fontFamily: 'Open Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '20px',
                            }}
                            noWrap
                        >
                            {group.name}
                        </Typography>
                    </Box>
                }
            />
        </>
    )
}

const GroupCount = ({hover, group}) => {
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    return (
        <Typography
            noWrap
            sx={{
                color: currentGroup.resource === group.resource && 'rgba(25, 103, 210, 1)',
                display: hover && 'none',
                marginRight: '-15px',
                maxWidth: 52,
            }}
            variant={'sideBarNumbers'}>
            {group.count}
        </Typography>
    )
}

const GroupSecondaryAction = ({hover, group}) => {
    return (
        <Stack direction={'row'} spacing={2}
               sx={{marginRight: '7px', alignItems: 'center', justifyContent: 'center'}}>
            <ShareLabelButtonIcon hover={hover} group={group}/>
        </Stack>
    )
}

const ShareLabelButtonIcon = ({hover, group}) => {
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const handleShareLabel = (event) => {
        event.stopPropagation();
        store.dispatch(setGroupsToShare({groups: [{group: group.resource}]}));
        store.dispatch(openShareLabelsDialog(true));
    };
    return (
        <Tooltip
            placement="bottom"
            title='Share label'
        >
            <span style={{alignItems: 'center', width: 18, height: 18}}>
                <SvgIcon
                    onClick={handleShareLabel}
                    sx={{
                        display: (!hover || !checkRole(RolesConstants.ROLE_SHARE, group)) && 'none',
                        width: 18,
                        height: 18,
                        fill: currentGroup.resource === group.resource ? '#3B7DED' : 'rgba(0, 0, 0, 0.32)',
                        ":hover": {
                            fill: currentGroup.resource === group.resource ? '#1659B6' : 'rgba(0, 0, 0, 0.6)',
                        },
                    }}
                >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_4951_25859)">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M13.0455 18L5.69335 14.3266L5.69227 14.3271V14.3261V11.388V11.3869L5.69335 11.3875L15.2515 6.61189V9.55104L8.63464 12.857L13.0455 15.0609V18ZM9.36926 5.14443L2.75146 8.45091V11.3901L12.3106 6.614L12.3107 6.61405V6.61395V3.67491V3.6748L12.3106 3.67486L4.95743 0.000976562V2.94012L9.36926 5.14443Z"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_4951_25859">
                                <rect width="18" height="18" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </SvgIcon>
            </span>
        </Tooltip>
    )
}

const ShareStatusIcon = ({group, hover, isChecked}) => {
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    if (group.share.length > 0) {
        if (group.is_user_owner) {
            const fill = currentGroup.resource === group.resource ? '#1967D2' : 'rgba(0, 0, 0, 0.54)';
            return (
                <SvgIcon sx={{
                    display: (isChecked || hover) && 'none',
                    width: 20,
                    height: 20,
                    flexShrink: 0,
                    marginLeft: '2px',
                }}
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M14 3C14.67 3 15.27 3.33 15.63 3.84L20 10L15.63 16.16C15.27 16.67 14.67 17 14 17L3 16.99C1.9 16.99 1 16.1 1 15V5C1 3.9 1.9 3.01 3 3.01L14 3ZM4 14C4.56 11.33 6.11 8.67 10 8.13V6L14 9.73L10 13.47V11.28C7.22 11.28 5.39 12.13 4 14Z"
                              fill={fill}/>
                    </svg>
                </SvgIcon>
            )
        }
        const fill = currentGroup.resource === group.resource ? '#1967D2' : 'rgba(0, 0, 0, 0.54)';
        return (
            <SvgIcon sx={{
                display: (isChecked || hover) && 'none',
                width: 20,
                height: 20,
                flexShrink: 0,
                marginLeft: '2px',
            }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M14 3C14.67 3 15.27 3.33 15.63 3.84L20 10L15.63 16.16C15.27 16.67 14.67 17 14 17L3 16.99C1.9 16.99 1 16.1 1 15V5C1 3.9 1.9 3.01 3 3.01L14 3ZM10 8C10 9.1 9.1 10 8 10C6.9 10 6 9.1 6 8C6 6.9 6.9 6 8 6C9.1 6 10 6.9 10 8ZM12 13V14H4V13C4 11.67 6.67 11 8 11C9.33 11 12 11.67 12 13Z"
                          fill={fill}/>
                </svg>
            </SvgIcon>
        )
    }
    const fill = currentGroup.resource === group.resource ? '#1967D2' : 'rgba(0, 0, 0, 0.54)';
    return (
        <SvgIcon sx={{
            display: (isChecked || hover) && 'none',
            width: 20,
            height: 20,
            flexShrink: 0,
            marginLeft: '2px',
        }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                    d="M15.63 3.84C15.27 3.33 14.67 3 14 3L3 3.01C1.9 3.01 1 3.9 1 5V15C1 16.1 1.9 16.99 3 16.99L14 17C14.67 17 15.27 16.67 15.63 16.16L20 10L15.63 3.84ZM14 15H3V5H14L17.55 10L14 15Z"
                    fill={fill}/>
            </svg>
        </SvgIcon>

    )
}

const CreateLabelSection = ({drawerWidth}) => {
    return (
        <ListItemButton
            disableRipple
            onClick={() => store.dispatch(openAddNewLabelDialog(true))}
            sx={{
                maxWidth: drawerWidth,
                borderRadius: '0px 20px 20px 0px',
                height: 40,
            }}
        >
            <ListItemIcon
                sx={{
                    marginRight: -2,
                    marginLeft: '6px',
                }}
            >
                <AddOutlined sx={{width: 20, height: 20}}/>
            </ListItemIcon>
            <ListItemText
                primary={
                    <Box
                        sx={{
                            width: '138px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '20px',
                            }}
                        >
                            Create label
                        </Typography>
                    </Box>
                }
            />
        </ListItemButton>
    )
}

const getLabelsByFilter = (groups, filter) => {
    let filteredGroups;
    switch (filter.labelsFilter.sortBy) {
        case 'last_modified':
            if (filter.labelsFilter.order === 'ASC') {
                groups.sort((a, b) => a.update_time > b.update_time ? 1 : -1)
            } else {
                groups.sort((a, b) => b.update_time > a.update_time ? 1 : -1);
            }
            break;
        case 'alphabetical':
            if (filter.labelsFilter.order === 'ASC') {
                groups.sort((a, b) => a.name > b.name ? 1 : -1);
            } else {
                groups.sort((a, b) => b.name > a.name ? 1 : -1);
            }
            break;
        default:
            if (filter.labelsFilter.order === 'ASC') {
                groups.sort((a, b) => a.update_time > b.update_time ? 1 : -1)
            } else {
                groups.sort((a, b) => b.update_time > a.update_time ? 1 : -1);
            }
    }
    switch (filter.labelsFilter.filter) {
        case 'all':
            filteredGroups = groups;
            break;
        case 'shared':
            filteredGroups = groups.filter(group => group.share.length && group.is_user_owner);
            break;
        case 'not_shared':
            filteredGroups = groups.filter(group => !group.share.length);
            break;
        case 'shared_with_me':
            filteredGroups = groups.filter(group => group.share.length && !group.is_user_owner);
            break;
        default:
            filteredGroups = groups;
    }
    filteredGroups = filteredGroups.filter(item => item.name.toLowerCase().includes(filter.searchLabelsFilter && filter.searchLabelsFilter.toLowerCase()));
    return filteredGroups;
}
