import * as RolesConstants from '../constants/roles'

const isDisabledDeleteLabel = (group) => {
    return !(group?.role === 'SHARE' || group?.role === 'EDIT');
};

const checkRole = (role, group) => {
    if (!group || Object.keys(group).length === 0) return false;
    if (group.is_user_owner) {
        return true
    }
    if (
        role === RolesConstants.ROLE_EDIT &&
        (group.role === RolesConstants.ROLE_SHARE ||
            group.role === RolesConstants.ROLE_DELETE ||
            group.role === RolesConstants.ROLE_EDIT)
    ) {
        return true
    }

    if (
        role === RolesConstants.ROLE_DELETE &&
        (group.role === RolesConstants.ROLE_SHARE ||
            group.role === RolesConstants.ROLE_DELETE)
    ) {
        return true
    }
    return role === RolesConstants.ROLE_SHARE &&
        group.role === RolesConstants.ROLE_SHARE;


}

export {
    checkRole,
    isDisabledDeleteLabel,
}
