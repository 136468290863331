import * as React from "react";
import {Box, Divider} from "@mui/material";

// project import
import Header from "../components/Header/Header";
import SidebarLabels from "../components/SideBarLabels/SideBarLabels";
import ContactsAppBar from "../components/Contacts/ContactsAppBar";
import Contacts from "../components/Contacts/Contacts";
import ShareLabelsDialog from "../components/SideBarLabels/ShareLabelsDialog";
import AddNewContactDialog from "../components/Contacts/AddNewContactDialog";
import AddExistingContactsDialog from "../components/Contacts/AddExistingContactsDialog";
import CreateLabelDialog from "../components/SideBarLabels/CreateLabelDialog";
import RenameLabelDialog from "../components/SideBarLabels/RenameLabelDialog";
import AlertDeleteLabelDialog from "../components/SideBarLabels/AlertDeleteLabelDialog";
import AlertRemoveContactFromLabelDialog from "../components/Contacts/AlertRemoveContactFromLabelDialog";
import EditContactDialog from "../components/Contacts/EditContactDialog";
import AlertDeleteContactDialog from "../components/Contacts/AlertDeleteContactDialog";
import AskPhoneDialog from "../components/AskPhoneDialog/AskPhoneDialog";
import WarningShareAllContactsDialog from "../components/SideBarLabels/WarningShareAllContactsDialog";
import MeetingDialog from "../components/Contacts/MeetingDialog";
import WelcomeDialog from "../components/WelcomeDialog/WelcomeDialog";
import InvitationPendingDialog from "../components/SideBarLabels/InvitationPendingDialog";
import RevokedCollaboratorDialog from "../components/SideBarLabels/RevokedCollaboratorDialog";

export default function ContactsPage() {
    return (
        <>
            <Box sx={{display: 'flex'}}>
                <Header/>
                <SidebarLabels/>
                <Box component="main" sx={{flexGrow: 1, marginLeft: 3, marginTop: '6px'}}>
                    <ContactsAppBar/>
                    <Divider/>
                    <Box sx={{marginLeft: 1}}>
                        <Contacts/>
                    </Box>
                </Box>
            </Box>
            <ShareLabelsDialog/>
            <AddNewContactDialog/>
            <EditContactDialog/>
            <AddExistingContactsDialog/>
            <CreateLabelDialog/>
            <RenameLabelDialog/>
            <AlertDeleteLabelDialog/>
            <AlertRemoveContactFromLabelDialog/>
            <AlertDeleteContactDialog/>
            <AskPhoneDialog/>
            <WarningShareAllContactsDialog/>
            <MeetingDialog/>
            <WelcomeDialog/>
            <InvitationPendingDialog/>
            <RevokedCollaboratorDialog/>
        </>
    )
}
