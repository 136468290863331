import * as React from 'react';
import {
    Avatar,
    Box, Chip,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Stack,
    SvgIcon,
    Tooltip,
    Typography
} from "@mui/material";
import {
    Cancel,
    CheckCircle,
    Clear,
    DeleteOutlineOutlined,
    ErrorOutlined,
    HelpOutlined,
    PauseCircleFilledOutlined,
    RemoveCircleOutlined,
} from "@mui/icons-material";
import {useSelector} from "react-redux";
import {v4 as uuidv4} from 'uuid';
import {useCallback, useLayoutEffect, useState} from "react";

// project import
import {StyledDataGrid} from "./StyledDataGrid";
import store from "../../store/store";
import {setManageUsersFilter} from "../../store/reducers/dataSlice";
import {
    setLicensesToDelete,
    setSelectedLicenses,
    subscriptionAddRestrictions,
    subscriptionRemoveRestrictions
} from "../../store/reducers/userSlice";
import {openAlertDeleteLicenseDialog} from "../../store/reducers/dialogSlice";

export default function ManageUsers() {
    const topHeight = 64 + 48 + 64 + 72 + 1;
    const [rowHover, setRowHover] = React.useState('');
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    const subscription = useSelector((state) => state.user.subscription);
    const shareableUsers = useSelector((state) => state.user.shareableUsers);
    const selectedLicenses = useSelector((state) => state.user.selectedLicenses);
    const user = useSelector((state) => state.user);
    const contacts = useSelector((state) => state.contacts.contacts);
    const manageUsersFilter = useSelector((state) => state.data.manageUsersFilter);
    const [sortModel, setSortModel] = React.useState([
        {
            field: 'name',
            sort: 'asc'
        }
    ]);
    const handleOnMouseEnter = (event) => {
        setRowHover(event.currentTarget.dataset.id);
    };
    const handleOnMouseLeave = () => {
        setRowHover(null);
    };
    const handleDeleteUserClick = (event, user) => {
        event.stopPropagation();
        const userId = user.userId || subscription.licensed_users.find(licensedUser => licensedUser.email === user.email).user_id;
        store.dispatch(setLicensesToDelete([userId]));
        store.dispatch(openAlertDeleteLicenseDialog(true));
    };
    const handleAddToRestrictedClick = (event, user) => {
        event.stopPropagation();
        store.dispatch(subscriptionAddRestrictions([user.email]));
    };
    const handleDeleteFromRestrictedClick = (event, user) => {
        event.stopPropagation();
        store.dispatch(subscriptionRemoveRestrictions([user.email]));
    };
    const handleDeleteSelectedUsersClick = () => {
        const usersIds = selectedLicenses.map(item => {
            const user = subscription.licensed_users.find(licensedUser => licensedUser.email === item);
            return user.user_id;
        })
        store.dispatch(setLicensesToDelete(usersIds));
        store.dispatch(openAlertDeleteLicenseDialog(true));
    };
    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 128,
            flex: 1,
            sortable: !selectedLicenses.length,
            disableColumnMenu: true,
            renderHeader: (params) => (
                selectedLicenses.length ? <>
                    <Tooltip
                        placement="bottom"
                        title='Remove license'
                    >
                        <IconButton
                            onClick={handleDeleteSelectedUsersClick}
                        >
                            <DeleteOutlineOutlined
                                sx={{
                                    width: 24,
                                    height: 24,
                                    flexShrink: 0,
                                    color: 'rgba(0, 0, 0, 0.54)',
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </> : <span>
                    {params.colDef.headerName}
                </span>

            ),
            renderCell: params => {
                return <Stack direction={'row'} spacing={1} sx={{alignItems: 'center'}}>
                    <Avatar src={params.row.photo}/>
                    <Box>{params.row.name}</Box>
                </Stack>
            }
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 128,
            flex: 1,
            sortable: !selectedLicenses.length,
            disableColumnMenu: true,
            renderHeader: (params) => (
                selectedLicenses.length ? <></> : <span>
                    {params.colDef.headerName}
                </span>
            ),
        },
        {
            field: 'domainGroups',
            headerName: 'Domain groups',
            flex: 1,
            sortable: !selectedLicenses.length,
            disableColumnMenu: true,
            renderHeader: (params) => (
                selectedLicenses.length ? <></> : <span>
                    {params.colDef.headerName}
                </span>
            ),
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 128,
            flex: 1,
            sortable: !selectedLicenses.length,
            renderHeader: (params) => (
                selectedLicenses.length ? <></> : <span>
                    {params.colDef.headerName}
                </span>
            ),
            renderCell: params => (
                <Stack direction={'row'} spacing={1} sx={{alignItems: 'center'}}>
                    {getStatusIcon(params.value)}
                    <Box>{params.value}</Box>
                </Stack>
            )
        },
        {
            field: 'license',
            headerName: 'Licensed',
            minWidth: 128,
            flex: 1,
            sortable: !selectedLicenses.length,
            renderHeader: (params) => (
                selectedLicenses.length ? <></> : <span>
                    {params.colDef.headerName}
                </span>
            ),
            renderCell: params => (
                <Stack direction={'row'} spacing={1} sx={{alignItems: 'center'}}>
                    {params.value === 'Licensed' ? <SvgIcon>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M13 8C13 5.79 11.21 4 9 4C6.79 4 5 5.79 5 8C5 10.21 6.79 12 9 12C11.21 12 13 10.21 13 8ZM1 18V20H17V18C17 15.34 11.67 14 9 14C6.33 14 1 15.34 1 18Z"
                                fill="black" fillOpacity="0.54"/>
                            <path d="M23 8L18 13L15 10" stroke="black" strokeOpacity="0.54" strokeWidth="2"/>
                        </svg>
                    </SvgIcon> : <SvgIcon>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M13 8C13 5.79 11.21 4 9 4C6.79 4 5 5.79 5 8C5 10.21 6.79 12 9 12C11.21 12 13 10.21 13 8ZM1 18V20H17V18C17 15.34 11.67 14 9 14C6.33 14 1 15.34 1 18Z"
                                fill="black" fillOpacity="0.54"/>
                            <path d="M22 8L19 11M16 14L19 11M19 11L16 8L22 14" stroke="black" strokeOpacity="0.54"
                                  strokeWidth="2"/>
                        </svg>
                    </SvgIcon>}
                    <Box>{params.value}</Box>
                </Stack>
            )
        },
        {
            field: 'memberShips',
            headerName: 'Labels',
            width: 470,
            sortable: !selectedLicenses.length,
            renderHeader: (params) => (
                params.colDef.headerName
            ),
            valueGetter: (params) => params.row.groups,
            renderCell: (params) => {
                return (
                    <>
                {
                    params.value?.reduce((acc, item, index) => {
                        if (index > 3) return acc;
                        if (index === 3) {
                            return (
                                [...acc, <Chip key={index} sx={{marginRight: 1}}
                                               label={<Typography
                                                   variant={'label'}>+{params.value.length - 3}</Typography>}
                                               size="medium"
                                               variant={'filled'} color={'default'}/>]
                            )
                        }
                        return (
                            [...acc, <Chip key={index}
                                           sx={{
                                               marginRight: 1,
                                               maxWidth: '200px',
                                           }}
                                           label={<Typography noWrap variant={'label'}>{item}</Typography>}
                                           size="medium"
                                           variant={'filled'} color={'default'}/>]
                        )
                    }, [])
                }
                    </>
                );
            }
        },
        {
            field: 'action',
            headerName: '',
            width: 168,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: (params) => (
                selectedLicenses.length ? <>
                    <Box display="flex"
                         justifyContent="right"
                         alignItems="right"
                         sx={{
                             width: '576px',
                         }}>
                        <Typography sx={{
                            color: '#3B7DED',
                            fontFamily: 'Open Sans',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '24px',
                        }}>Selected: {selectedLicenses.length}</Typography>
                    </Box></> : <>
                    {params.colDef.headerName}
                </>
            ),
            renderCell: params => {
                return params.id === rowHover && params.row.license === 'Licensed' && !params.row.isAdmin && subscription.status !== 2 &&
                    // return <Stack direction={'row'}>
                    <Tooltip
                        placement="bottom"
                        title='Remove license'
                    >
                        <IconButton
                            onClick={(event) => handleDeleteUserClick(event, params.row)}
                        >
                            <DeleteOutlineOutlined
                                sx={{
                                    width: 24,
                                    height: 24,
                                    flexShrink: 0,
                                    color: 'rgba(0, 0, 0, 0.54)',
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                // <Tooltip
                //     placement="bottom"
                //     title='Add to restriction users'
                // >
                //     <IconButton
                //         onClick={(event) => handleAddToRestrictedClick(event, params.row)}
                //     >
                //         <CloseOutlined
                //             sx={{
                //                 width: 24,
                //                 height: 24,
                //                 flexShrink: 0,
                //                 color: 'red',
                //             }}
                //         />
                //     </IconButton>
                // </Tooltip>
                // <Tooltip
                //     placement="bottom"
                //     title='Remove from restriction users'
                // >
                //     <IconButton
                //         onClick={(event) => handleDeleteFromRestrictedClick(event, params.row)}
                //     >
                //         <DeleteOutlineOutlined
                //             sx={{
                //                 width: 24,
                //                 height: 24,
                //                 flexShrink: 0,
                //                 color: 'red',
                //             }}
                //         />
                //     </IconButton>
                // </Tooltip>
                // </Stack>
            }
        },
    ];
    // const rows = getUsersByFilter(USERS, {status: manageUsersFilter.status, license: manageUsersFilter.license});
    const rows = getUsersByFilter(getUsers(subscription, contacts, shareableUsers.users, user), {
        status: manageUsersFilter.status,
        license: manageUsersFilter.license
    });
    const [dataGridKey, setDataGridKey] = useState(0);
    const updateDataGridKey = useCallback(() => {
        if (dataGridKey !== window.innerHeight) {
            setDataGridKey(window.innerHeight)
        }
    }, [dataGridKey]);
    useLayoutEffect(() => {
        window.addEventListener('resize', updateDataGridKey)
        return () => window.removeEventListener('resize', updateDataGridKey)
    }, [updateDataGridKey]);
    return (
        <div style={{
            height: domainInstallAlert ? `calc(100vh - ${topHeight}px - 48px)` : `calc(100vh - ${topHeight}px - 20px)`,
            width: '100%',
            // display: "table",
            // tableLayout: "fixed",
        }}>
            <StyledDataGrid
                key={dataGridKey}
                checkboxSelection
                rows={rows}
                columns={columns}
                columnHeaderHeight={56}
                rowHeight={72}
                autoPageSize
                disableColumnMenu={selectedLicenses.length !== 0}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    store.dispatch(setSelectedLicenses(newRowSelectionModel));
                }}
                rowSelectionModel={selectedLicenses}
                isRowSelectable={(params) => params.row.license === 'Licensed' && !params.row.isAdmin}
                slotProps={{
                    row: {
                        onMouseEnter: handleOnMouseEnter,
                        onMouseLeave: handleOnMouseLeave,
                    },
                }}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                slots={{
                    columnMenu: CustomColumnMenu,
                }}
                columnVisibilityModel={{
                    domainGroups: false,
                    status: false,
                }}
            />
        </div>
    );
}

function getStatusIcon(status) {
    let icon;
    switch (status) {
        case 'Normal':
            icon = <CheckCircle color={"success"}/>;
            break;
        case 'Deleted':
            icon = <Cancel color={"error"}/>;
            break;
        case 'Suspended':
            icon = <PauseCircleFilledOutlined sx={{color: 'rgba(237, 159, 2, 1)'}}/>;
            break;
        case 'Banned':
            icon = <RemoveCircleOutlined color={"error"}/>;
            break;
        case 'Revoked':
            icon = <ErrorOutlined color={"error"}/>;
            break;
        case 'Pending':
            icon = <SvgIcon>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z"
                        fill="#ED9F02"/>
                    <path
                        d="M12 6V3.75L9 6.75L12 9.75V7.5C14.4825 7.5 16.5 9.5175 16.5 12C16.5 12.7575 16.3125 13.4775 15.975 14.1L17.07 15.195C17.655 14.2725 18 13.1775 18 12C18 8.685 15.315 6 12 6ZM12 16.5C9.5175 16.5 7.5 14.4825 7.5 12C7.5 11.2425 7.6875 10.5225 8.025 9.9L6.93 8.805C6.345 9.7275 6 10.8225 6 12C6 15.315 8.685 18 12 18V20.25L15 17.25L12 14.25V16.5Z"
                        fill="white"/>
                </svg>
            </SvgIcon>;
            break;
        case 'Unknown':
            icon = <HelpOutlined sx={{color: 'rgba(237, 159, 2, 1)'}}/>;
            break;
        case 'Clear Filter':
            icon = <Clear color={"inherit"}/>;
            break;
        default:
            icon = <HelpOutlined sx={{color: 'rgba(237, 159, 2, 1)'}}/>;
    }
    return icon;
}

const getUsersByFilter = (users, filter) => {
    let filteredUsers = users;
    if (filter.status) {
        filteredUsers = users.filter(user => user.status === filter.status);
    }
    if (filter.license) {
        filteredUsers = filteredUsers.filter(user => user.license === filter.license);
    }
    return filteredUsers;
}

function CustomColumnMenu(props) {
    const manageUsersFilter = useSelector((state) => state.data.manageUsersFilter);
    const itemProps = {
        colDef: props.colDef,
        hideMenu: props.hideMenu,
    };
    // const statuses = ['Normal', 'Deleted', 'Suspended', 'Banned', 'Revoked', 'Pending', 'Unknown'];
    const statuses = ['Normal', 'Deleted', 'Banned', 'Unknown'];
    const licenses = ['Licensed', 'Unlicensed'];
    return (
        <>
            {itemProps.colDef.field === 'status' ? (
                [...statuses.map(status => <StatusMenuComponent key={uuidv4()} {...itemProps} status={status}/>),
                    <><Divider/><MenuItem onClick={(event) => {
                        props.hideMenu(event);
                        store.dispatch(setManageUsersFilter({
                            license: manageUsersFilter.license ?? null,
                            status: null
                        }));
                    }}>
                        <Stack spacing={1} direction={'row'} sx={{alignItems: 'center'}}><Clear
                            color={"inherit"}/><Typography>Clear filter</Typography></Stack>
                    </MenuItem></>]
            ) : null}
            {itemProps.colDef.field === 'license' ? (
                [...licenses.map(license => <LicenseMenuComponent key={uuidv4()} {...itemProps} license={license}/>),
                    <><Divider/><MenuItem onClick={(event) => {
                        props.hideMenu(event);
                        store.dispatch(setManageUsersFilter({license: null, status: manageUsersFilter.status ?? null}));
                    }}>
                        <Stack spacing={1} direction={'row'} sx={{alignItems: 'center'}}><Clear
                            color={"inherit"}/><Typography>Clear filter</Typography></Stack>
                    </MenuItem></>]
            ) : null}
        </>
    );
}

function StatusMenuComponent({hideMenu, status}) {
    const manageUsersFilter = useSelector((state) => state.data.manageUsersFilter);
    const handleClickStatusFilter = (status) => {
        const filter = status === 'Clear Filter' ? {
            license: null,
            status: null
        } : {license: manageUsersFilter.license ?? null, status};
        store.dispatch(setManageUsersFilter(filter));
    };
    return (
        <MenuItem onClick={(event) => {
            hideMenu(event);
            handleClickStatusFilter(status);
        }}>
            <ListItemIcon>
                {getStatusIcon(status)}
            </ListItemIcon>
            <ListItemText>{status}</ListItemText>
        </MenuItem>
    )
}

function LicenseMenuComponent({hideMenu, license}) {
    const manageUsersFilter = useSelector((state) => state.data.manageUsersFilter);
    const handleClickLicenseFilter = (license) => {
        const filter = license === 'Clear Filter' ? {license: null, status: null} : {
            license,
            status: manageUsersFilter.status ?? null
        };
        store.dispatch(setManageUsersFilter(filter));
    };
    return (
        <MenuItem onClick={(event) => {
            hideMenu(event);
            handleClickLicenseFilter(license);
        }}>
            <ListItemIcon>
                {license === 'Licensed' ? <SvgIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M13 8C13 5.79 11.21 4 9 4C6.79 4 5 5.79 5 8C5 10.21 6.79 12 9 12C11.21 12 13 10.21 13 8ZM1 18V20H17V18C17 15.34 11.67 14 9 14C6.33 14 1 15.34 1 18Z"
                            fill="black" fillOpacity="0.54"/>
                        <path d="M23 8L18 13L15 10" stroke="black" strokeOpacity="0.54" strokeWidth="2"/>
                    </svg>
                </SvgIcon> : <SvgIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M13 8C13 5.79 11.21 4 9 4C6.79 4 5 5.79 5 8C5 10.21 6.79 12 9 12C11.21 12 13 10.21 13 8ZM1 18V20H17V18C17 15.34 11.67 14 9 14C6.33 14 1 15.34 1 18Z"
                            fill="black" fillOpacity="0.54"/>
                        <path d="M22 8L19 11M16 14L19 11M19 11L16 8L22 14" stroke="black" strokeOpacity="0.54"
                              strokeWidth="2"/>
                    </svg>
                </SvgIcon>}

            </ListItemIcon>
            <ListItemText><Typography>{license}</Typography></ListItemText>
        </MenuItem>
    )
}

const getUsers = (subscription, contacts, shareableUsers, user) => {
    // if (Object.keys(subscription).length === 0 || Object.keys(user.domainData).length === 0 || Object.keys(contacts).length === 0) return [];
    const licensedUsers = subscription.status === 2 ? subscription.connected_users : subscription.licensed_users;
    let connectedUsers = licensedUsers.map(connData => {
        const groupNames = connData.groups ? connData.groups.map(el => el.name) : [];
        let contact = {...contacts.find(el => el.email === connData.email)};
        const isAdmin = subscription.subscription.owner_id ? subscription.subscription.owner_id === connData.user_id : connData.is_admin;
        if (contact.length) {
            contact.id = connData.email;
            contact.groups = groupNames;
            contact.domainGroups = connData.domains_groups;
            contact.status = getUserStatus(subscription, connData);
            contact.license = 'Licensed';
            contact.userId = connData.user_id;
            contact.isAdmin = isAdmin;
            contact.name = connData.name || connData.email;
        } else {
            const userData = user.user;
            if (connData.email === userData.email) {
                contact = {
                    id: connData.email,
                    email: connData.email,
                    groups: groupNames,
                    domainGroups: connData.domains_groups,
                    name: userData.name || userData.email,
                    photo: userData.photo,
                    status: getUserStatus(subscription, connData),
                    license: 'Licensed',
                    userId: connData.user_id,
                    isAdmin,
                }
            } else {
                contact = {
                    id: connData.email,
                    email: connData.email,
                    groups: groupNames,
                    domainGroups: connData.domains_groups || null,
                    status: getUserStatus(subscription, connData),
                    license: 'Licensed',
                    userId: connData.user_id,
                    isAdmin,
                }
                const shareable = shareableUsers.find(el => el.email === connData.email);
                contact.name = shareable ? shareable.name || connData.email : connData.email;
                // if (shareable && shareable.name) {
                //     contact.name = shareable.name;
                // } else if (connData.family_name && connData.given_name) {
                //     contact.name = `${connData.family_name} ${connData.given_name}`;
                // } else {
                //     contact.name = connData.email
                // }
            }
        }
        return contact;
    })
    if (subscription.status !== 2) {
        const uniqueRestrictedUsers = subscription.restricted_users ? subscription.restricted_users.filter(restrictedUser => {
            return !connectedUsers.some(connectedUser => connectedUser.email === restrictedUser.email);
        }) : [];
        const restrictedUsers = uniqueRestrictedUsers.map(item => {
            const contact = contacts.find(el => el.email === item.email);
            const name = contact ? contact.name : item.email;
            return {
                ...item,
                status: 'Banned',
                id: item.user_id,
                license: 'Unlicensed',
                name,
            }
        });
        connectedUsers = [...connectedUsers, ...restrictedUsers];
        const uniqueDeletedUsers = subscription.deleted_users ? subscription.deleted_users.filter(deletedUser => {
            return !connectedUsers.some(connectedUser => connectedUser.email === deletedUser.email);
        }) : [];
        const deletedUsers = uniqueDeletedUsers.map(item => {
            const contact = contacts.find(el => el.email === item.email);
            const name = contact ? contact.name : item.email;
            return {
                ...item,
                status: 'Deleted',
                id: item.user_id,
                license: 'Unlicensed',
                name,
            }
        });
        connectedUsers = [...connectedUsers, ...deletedUsers];
    }
    if (user.user.domain_user && user.domainData.users) {
        const uniqueDomainUsers = user.domainData.users.filter(domainUser => {
            return !connectedUsers.some(connectedUser => connectedUser.email === domainUser.email);
        });
        const domainUsers = uniqueDomainUsers.map(domainUser => {
            const licensedUser = subscription.licensed_users.find(licensedUser => licensedUser.email === domainUser.email);
            return {
                id: domainUser.email,
                email: domainUser.email,
                name: domainUser.name?.fullName || domainUser.email,
                groups: null,
                domainGroups: domainUser && domainUser.domains_groups?.length ? domainUser.domains_groups.join(',') : '',
                status: getUserStatus(subscription, domainUser),
                license: licensedUser ? 'Licensed' : 'Unlicensed',
                isAdmin: domainUser.email === user.user.email && user.user.domain_admin,
                userId: null,
            }
        });
        connectedUsers = [...connectedUsers, ...domainUsers];
    }
    return connectedUsers
}

const getUserStatus = (subscription, connData) => {
    const isDeleted = subscription.deleted_users ? subscription.deleted_users.find(item => item.email === connData.email) : false;
    if (isDeleted) return 'Deleted';
    const isBanned = subscription.restricted_users ? subscription.restricted_users.find(item => item.email === connData.email) : false;
    if (isBanned) return 'Banned';
    return 'Normal'
}
