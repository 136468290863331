import * as React from "react";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Box, Divider, Drawer, Toolbar} from "@mui/material";

// project import
import {SkeletonAllLabels, SkeletonLabels, SkeletonLabelsPanel} from "../Skeletons";
import {Labels} from "./Labels";
import {AllContactsLabel} from "./AllContacts";
import {LabelsPanel} from "./LabelsPanel";
import store from "../../store/store";
import {openMeetingDialog} from "../../store/reducers/dialogSlice";
import {isNotTrial, isTrialOver} from "../../services/subscriptionStatus";
import SubscriptionStatusAlert from "../Alerts/SubscriptionStatusAlert";

const drawerWidth = 260;

export default function SidebarLabels() {
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    const [ref, setRef] = useState();
    const [margin, setMargin] = useState(0);


    const groupsInitialized = useSelector((state) => state.groups.groupsInitialized);
    useEffect(() => {
        if (ref) {
            setMargin(ref.clientHeight);
        }
    }, [ref]);
    return (
        <Drawer
            variant="permanent"
            PaperProps={{
                style: {
                    overflow: "hidden"
                }
            }}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box', border: 0},
            }}
        >
            <Toolbar/>
            {domainInstallAlert && <Toolbar variant="dense"/>}
            <Box style={{overflowY: "auto", marginBottom: margin}}>
                {!groupsInitialized? <SkeletonAllLabels/> : <AllContactsLabel/>}
                <Divider/>
                {!groupsInitialized ? <SkeletonLabelsPanel/> : <LabelsPanel drawerWidth={drawerWidth}/>}
                {!groupsInitialized ? <SkeletonLabels/> : <Labels drawerWidth={drawerWidth}/>}
            </Box>
            <SubscriptionStatusAlert setRef={setRef}/>
        </Drawer>
    )
}
