import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import {Box, Grid, Toolbar} from "@mui/material";
import {v4 as uuidv4} from 'uuid';

export function SkeletonAllLabels() {
    return (
        <Box
            sx={{
                width: '215px',
                marginTop: '22px',
                marginBottom: '8px',
                marginLeft: '26px',
            }}
        >
            <Grid container spacing={1}>
                <Grid item md={7}>
                    <Skeleton variant="rounded" height={40}/>
                </Grid>
                <Grid item md={2.5}>
                    <Box height={40}/>
                </Grid>
                <Grid item md={2.5}>
                    <Skeleton variant="rounded" height={40}/>
                </Grid>
            </Grid>
        </Box>
    );
}

export function SkeletonLabelsPanel() {
    return (
        <Box
            sx={{
                width: '215px',
                marginLeft: '26px',
                marginTop: '8px',
            }}
        >
            <Grid container spacing={1}>
                <Grid item md={7}>
                    <Skeleton variant="rounded" height={40}/>
                </Grid>
                <Grid item md={2.5}>
                    <Skeleton variant="rounded" height={40}/>
                </Grid>
                <Grid item md={2.5}>
                    <Skeleton variant="rounded" height={40}/>
                </Grid>
            </Grid>
        </Box>
    );
}

export function SkeletonLabels() {
    return (
        <Stack spacing={2}
               sx={{
                   width: '215px',
                   marginLeft: '26px',
                   marginTop: '24px',
               }}
        >
            <Skeleton variant="rounded" height={40}/>
            <Skeleton variant="rounded" height={40}/>
            <Skeleton variant="rounded" height={40}/>
            <Skeleton variant="rounded" height={40}/>
            <Skeleton variant="rounded" height={40}/>
        </Stack>
    );
}

export function SkeletonContactsAppBar({DrawerHeader}) {
    return (
        <>
            <DrawerHeader/>
            <Toolbar
                sx={{
                    width: 'auto',
                }}
            >
                <Grid container spacing={1}>
                    <Grid item md={3} ml={-1.2}>
                        <Skeleton variant="rounded" height={40}/>
                    </Grid>
                    <Grid item md={7}>
                        <Box height={40}/>
                    </Grid>
                    <Grid item md={2}>
                        <Skeleton variant="rounded" height={40}/>
                    </Grid>
                </Grid>
            </Toolbar>
        </>
    );
}

export function SkeletonContacts() {
    return (
        <Box
            sx={{
                width: '1458px',
                marginLeft: '7px',
            }}
        >
            {[...Array(9).keys()].map(() => <OneRowContact key={uuidv4()}/>)}
        </Box>
    );
}

export function OneRowContact() {
    return (
        <Grid container spacing={1}
              sx={{
                  mt: 2
              }}
        >
            <Grid item md={1}>
                <Skeleton variant="rounded" height={40}/>
            </Grid>
            <Grid item md={11}>
                <Skeleton variant="rounded" height={40}/>
            </Grid>
        </Grid>
    )
}

export function SkeletonQuantityLicenses() {
    return (
        <Stack
            sx={{
                width: 496,
                marginTop: 4,
            }}
        >
            <Grid container spacing={1}>
                <Grid item md={7}>
                    <Skeleton variant="rounded" height={40}/>
                </Grid>
                <Grid item md={1}>
                    <Box height={40}/>
                </Grid>
                <Grid item md={4}>
                    <Skeleton variant="rounded" height={40}/>
                </Grid>
            </Grid>
            <Box
                sx={{
                    width: 496,
                    height: 372,
                    marginTop: 3,
                }}
            >
                <Skeleton variant="rounded" height={372}/>
            </Box>
        </Stack>
    );
}
