import {Box, Container, LinearProgress, Stack, SvgIcon, Typography} from "@mui/material";
import * as React from "react";
import {useSelector} from "react-redux";

export default function CheckingEligibility() {
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    return (
        <Container disableGutters sx={{width: '400px'}}>
            <Stack
                spacing={1}
                sx={{
                    display: 'flex',
                    width: '100%',
                    minHeight: domainInstallAlert ? 'calc(100vh - 64px - 48px)' : 'calc(100vh - 64px)',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <SvgIcon sx={{width: 96, height: 96}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
                        <rect width="96" height="96" rx="48" fill="#C2D8FF"/>
                        <path
                            d="M47.968 16C30.304 16 16 30.336 16 48C16 65.664 30.304 80 47.968 80C65.664 80 80 65.664 80 48C80 30.336 65.664 16 47.968 16Z"
                            fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M16 48C16 30.336 30.336 16 48 16C65.664 16 80 30.336 80 48C80 65.664 65.664 80 48 80C30.336 80 16 65.664 16 48ZM32 48C32 45.8 33.8 44 36 44C38.2 44 40 45.8 40 48C40 50.2 38.2 52 36 52C33.8 52 32 50.2 32 48ZM56 48C56 45.8 57.8 44 60 44C62.2 44 64 45.8 64 48C64 50.2 62.2 52 60 52C57.8 52 56 50.2 56 48ZM44 48C44 45.8 45.8 44 48 44C50.2 44 52 45.8 52 48C52 50.2 50.2 52 48 52C45.8 52 44 50.2 44 48Z"
                              fill="#3B7DED"/>
                    </svg>
                </SvgIcon>
                <Box sx={{width: '100px'}}>
                    <LinearProgress sx={{marginTop: 2, marginBottom: 2}}/>
                </Box>
                <Typography
                    sx={{
                        textAlign: 'center',
                        color: 'rgba(60, 64, 67, 1)',
                        fontFamily: `"Open Sans"`,
                        fontSize: 28,
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '40px',
                        letterSpacing: '-0.56px',
                    }}>We’re checking your eligibility</Typography>
                <Typography
                    sx={{
                        padding: '0 20px 0 20px',
                        textAlign: 'center',
                        color: 'rgba(0, 0, 0, 0.6)',
                        fontFamily: `"Open Sans"`,
                        fontSize: 17,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '-0.34px',
                        fontFeatureSettings: "'clig' off, 'liga' off",
                    }}>We are checking if your account is eligible for an extra free trial.</Typography>
            </Stack>
        </Container>

    )
}
