import * as React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
    IconButton, Avatar, Stack, TextField, InputAdornment,
} from "@mui/material";
import {CloseOutlined, SearchOutlined} from '@mui/icons-material';
import {useSelector} from "react-redux";
import {styled} from "@mui/material/styles";

// project import
import store from "../../store/store";
import {openAddUserToSubscriptionDialog} from "../../store/reducers/dialogSlice";
import {StyledDataGrid} from "./StyledDataGrid";
import {getSubscriptionData, subscriptionAddUsers} from "../../store/reducers/userSlice";

export default function AddUserToSubscriptionDialog() {
    const open = useSelector((state) => state.dialogsOpen.addUserToSubscription.value);
    const subscription = useSelector((state) => state.user.subscription);
    const contacts = useSelector((state) => state.contacts.contacts);
    const shareableUsers = useSelector((state) => state.user.shareableUsers);
    const deletedUsers = subscription.deleted_users ? subscription.deleted_users.map(user => {
        const contact = contacts.find(contact => contact.email === user.email);
        const name = contact ? contact.name : null;
        return {...user, id: user.email, name}
    }) : [];
    const restrictedUsers = subscription.restricted_users ? subscription.restricted_users.map(user => ({
        ...user,
        id: user.email
    })) : [];
    let unlicensedUsers = shareableUsers.users.reduce((acc, user) => {
        return subscription.licensed_users.some(licensedUser => licensedUser.email === user.email) ? acc : [...acc, {
            ...user,
            id: user.email
        }];
    }, []);
    const uniqueRestrictedUsers = restrictedUsers.filter(restrictedUser => {
        return !unlicensedUsers.some(unlicensedUser => unlicensedUser.email === restrictedUser.email);
    });
    unlicensedUsers = [...unlicensedUsers, ...uniqueRestrictedUsers];
    const uniqueDeletedUsers = deletedUsers.filter(deletedUser => {
        return !unlicensedUsers.some(unlicensedUser => unlicensedUser.email === deletedUser.email);
    });
    unlicensedUsers = [...unlicensedUsers, ...uniqueDeletedUsers];
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const [searchUsersFilter, setSearchUsersFilter] = React.useState('');
    const handleClose = () => {
        store.dispatch(openAddUserToSubscriptionDialog(false));
        setRowSelectionModel([]);
    };
    const handleAddContacts = async () => {
        handleClose();
        await store.dispatch(subscriptionAddUsers(rowSelectionModel));
        store.dispatch(getSubscriptionData());
    };
    const [sortModel, setSortModel] = React.useState([
        {
            field: 'name',
            sort: 'asc'
        }
    ]);
    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: params => (
                <Stack direction={'row'} spacing={1} sx={{alignItems: 'center'}}>
                    <Avatar src={params.row.photo}/>
                    <Box>{params.row.name || params.row.email}</Box>
                </Stack>
            )
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
        },
        {
            field: 'domainGroup',
            headerName: 'Domain groups',
            flex: 1,
        },
    ];
    const rows = getUsersByFilter(unlicensedUsers, searchUsersFilter);
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        width: 840,
                        maxWidth: 840,
                        minWidth: 444,
                        backgroundColor: 'rgba(59, 125, 237, 1)',
                    }
                }}
            >
                <DialogTitle sx={{backgroundColor: 'rgba(59, 125, 237, 1)'}}>
                    <Grid container sx={{alignItems: 'center'}}>
                        <Grid item>
                            <Typography
                                variant={'dialogTitle'} color={'rgba(255, 255, 255, 1)'}>Add users to Subscription</Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 12,
                        top: 12,
                        color: 'rgba(255, 255, 255, 0.54)',
                        '&:hover': {
                            color: 'rgba(255, 255, 255, 0.87)',
                            backgroundColor: 'rgba(0, 0, 0, 0.16)'
                        },
                    }}
                >
                    <CloseOutlined/>
                </IconButton>
                <DialogContent dividers sx={{border: 0, backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                    <Stack spacing={'20px'}>
                        <Box
                            sx={{
                                width: 'auto',
                            }}>
                            <Typography
                                noWrap
                                sx={{
                                    color: 'rgba(0, 0, 0, 0.54)',
                                    fontSize: 16,
                                    fontFamily: `"Roboto"`,
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '24px',
                                    letterSpacing: '0.2px',
                                }}
                            >
                                Select the users you wish to add
                            </Typography>
                        </Box>
                        <StyledTextField
                            sx={{
                                width: '100%',
                                height: 42,
                                display: "flex",
                                justifyContent: "center",
                            }}
                            onChange={(event) => {
                                setSearchUsersFilter(event.target.value);
                            }}
                            value={searchUsersFilter}
                            id="input-with-icon-textfield"
                            placeholder="Search for users"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" sx={{marginLeft: '-3px',}}>
                                        <SearchOutlined sx={{color: 'rgba(0, 0, 0, 0.54)'}}/>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    searchUsersFilter && <InputAdornment position="end">
                                        <CloseOutlined
                                            sx={{
                                                width: 20,
                                                height: 20,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setSearchUsersFilter('');
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                                style: {
                                    height: '42px',
                                    width: '100%',
                                    color: 'rgba(32, 33, 36, 1)',
                                    fontSize: '16px',
                                    fontFamily: 'Open Sans',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '46px',
                                    letterSpacing: '-0.32px',
                                }
                            }}
                        />
                        <Box
                            sx={{
                                width: '100%',
                                height: '452px',
                            }}
                        >
                            <StyledDataGrid
                                rows={rows}
                                columns={columns}
                                columnHeaderHeight={56}
                                rowHeight={72}
                                autoPageSize
                                checkboxSelection
                                disableColumnMenu
                                disableColumnSelector
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    setRowSelectionModel(newRowSelectionModel);
                                }}
                                rowSelectionModel={rowSelectionModel}
                                sortModel={sortModel}
                                onSortModelChange={(model) => setSortModel(model)}
                            />
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions sx={{backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                    <Button onClick={handleClose}><Typography variant={'dialogButton'} color={'rgba(0, 0, 0, 0.54)'}>Cancel</Typography></Button>
                    <Button onClick={handleAddContacts} disabled={rowSelectionModel.length === 0}
                            sx={{
                                color: rowSelectionModel.length === 0 ? 'rgba(0, 0, 0, 0.32)' : 'rgba(59, 125, 237, 1)'
                            }}
                    ><Typography
                        variant={'dialogButton'}>
                        Add users
                    </Typography></Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

const StyledTextField = styled(TextField)(() => ({
    borderRadius: 8,
    backgroundColor: '#F1F3F4',
    '.MuiInput-root': {
        height: '42px',
    },
    '& label.Mui-focused': {
        color: '#A0AAB4',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#F1F3F4',
            borderRadius: 8,
        },
        '&:hover fieldset': {
            borderColor: '#F1F3F4',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#F1F3F4',
        },
    },
}));

const getUsersByFilter = (users, filter) => {
    return users.filter(item => item.name?.toLowerCase().includes(filter && filter.toLowerCase()) || item.email.toLowerCase().includes(filter && filter.toLowerCase()));
}
