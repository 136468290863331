import {Alert, Box, Button, Stack, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {AddCardOutlined, CalendarTodayOutlined} from "@mui/icons-material";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import store from "../../store/store";
import {openMeetingDialog} from "../../store/reducers/dialogSlice";

// project import

export default function SubscriptionStatusAlert({setRef}) {
    const navigate = useNavigate();
    const subscription = useSelector((state) => state.user.subscription);
    const TRIAL = subscription.plan_id === 1;
    const isTrialNotOver = (TRIAL && subscription.status !== 0);
    if (!isTrialNotOver || !subscription.trail_activated) return null;
    const handleOpenMeetingDialog = () => {
        store.dispatch(openMeetingDialog(true));
    };
    const remainingDays = () => {
        let message = `${subscription.remaining_days} days`;
        if (subscription.remaining_days <= 1) {
            message = 'today';
        }
        if (subscription.remaining_days === 2){
            message = 'tomorrow';
        }
        return message;
    }
    const message = <>Free trial expires {subscription.remaining_days > 2 && 'in'} <Typography
        component={'span'}
        sx={{
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            /* identical to box height, or 143% */
            textAlign: 'center',
            letterSpacing: '0.17px',
            color: '#013654',
        }}
    >{remainingDays()}</Typography></>;
    return (
        <Box
            ref={setRef}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '20px',
                gap: '10px',
                position: 'absolute',
                width: '260px',
                height: '176px',
                left: '0px',
                bottom: '0px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    padding: '12px',
                    gap: '12px',
                    width: '220px',
                    height: '136px',
                    background: '#E6F3FA',
                    borderRadius: '4px',
                    /* Inside auto layout */
                    flex: 'none',
                    order: 0,
                    alignSelf: 'stretch',
                    flexGrow: 0,
                }}
            >
                <Stack spacing={'12px'}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            padding: '0px',
                            gap: '16px',
                            width: '196px',
                            height: '20px',
                            /* Inside auto layout */
                            flex: 'none',
                            order: 0,
                            alignSelf: 'stretch',
                            flexGrow: 0,
                        }}
                    >
                        <Typography
                            sx={{
                                /* Free trial expires in 14 days */
                                width: '196px',
                                height: '20px',
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '20px',
                                /* identical to box height, or 143% */
                                textAlign: 'center',
                                letterSpacing: '0.17px',
                                color: '#013654',
                                /* Inside auto layout */
                                flex: 'none',
                                order: 0,
                                alignSelf: 'stretch',
                                flexGrow: 0,
                            }}
                        >
                            {message}
                        </Typography>
                    </Box>
                    <Stack spacing={1}>
                        <Button
                            variant='outlined'
                            color={'buttonOutlinedSidebarLabels'}
                            sx={{
                                width: 196,
                                borderColor: 'rgba(1, 54, 84, 0.24)'
                            }}
                            startIcon={<AddCardOutlined sx={{width: 18, height: 18, color: 'rgba(1, 54, 84, 1)'}}/>}
                            onClick={() => navigate("/manage_subscription")}
                        >
                            <Typography
                                variant={'button'}
                                sx={{
                                    textTransform: "none",
                                    letterSpacing: 0,
                                    fontSize: 13,
                                    color: 'rgba(1, 54, 84, 1)'
                                }}
                            >
                                Add payment details
                            </Typography>
                        </Button>
                        <Button
                            variant='outlined'
                            color={'buttonOutlinedSidebarLabels'}
                            sx={{
                                width: 196,
                                borderColor: 'rgba(1, 54, 84, 0.24)'
                            }}
                            startIcon={<CalendarTodayOutlined sx={{width: 18, height: 18, color: 'rgba(1, 54, 84, 1)'}}/>}
                            onClick={handleOpenMeetingDialog}
                        >
                            <Typography
                                variant={'button'}
                                sx={{
                                    textTransform: "none",
                                    letterSpacing: 0,
                                    fontSize: 13,
                                    color: 'rgba(1, 54, 84, 1)'
                                }}
                            >
                                Book a demo
                            </Typography>
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    )
}
