import * as React from 'react';
import {
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridHeaderCheckbox
} from "@mui/x-data-grid";
import {Avatar, Box, Chip, Stack, Tooltip, Typography, IconButton, SvgIcon, Button} from "@mui/material";
import {useSelector} from "react-redux";
import {
    LabelOutlined,
    LabelOffOutlined,
    ModeEditOutlined,
    DeleteOutlineOutlined,
    PersonAddAltOutlined,
} from '@mui/icons-material';
import {useCallback, useLayoutEffect, useState} from "react";

// project import
import store from "../../store/store";
import {selectGroups} from "../../store/reducers/groupsSlice";
import {
    openAddContactDialog,
    openAddContactToLabelsDialog, openAlertDeleteContactDialog,
    openAlertRemoveContactFromLabelDialog,
    openEditContactDialog,
} from "../../store/reducers/dialogSlice";
import {getFullContact, setRowSelectionModel, setContactsToDelete} from "../../store/reducers/contactsSlice";
import AddContactsToLabelsDialog from "./AddContactsToLabelsDialog";
import {SkeletonContacts} from "../Skeletons";
import {StyledDataGrid} from "./StyledDataGrid";
import {checkRole, isDisabledDeleteLabel} from "../../services/checkRole";
import * as RolesConstants from "../../constants/roles";
import AddContactsMenu from "./AddContactsMenu";

export default function Contacts() {
    const topHeight = 64 + 64 + 2;
    const statusFirstLoad = useSelector((state) => state.contacts.statusFirstLoad);
    const contacts = useSelector((state) => state.contacts.contacts);
    const selectedContacts = useSelector((state) => state.contacts.selectedContacts);
    const rowSelectionModel = useSelector((state) => state.contacts.rowSelectionModel);
    const searchContactsFilter = useSelector((state) => state.contacts.searchContactsFilter);
    const groups = useSelector(selectGroups);
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const isCheckedContacts = !!rowSelectionModel.length;
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    const handleClickCopyContactsToLabel = async () => {
        await store.dispatch(openAddContactToLabelsDialog(true));
    };
    const handleClickRemoveContactsFromGroup = async () => {
        store.dispatch(openAlertRemoveContactFromLabelDialog(true));
    };
    const handleClickEditContact = async (event, id) => {
        event.stopPropagation();
        store.dispatch(getFullContact(id));
        store.dispatch(openEditContactDialog(true));
    };
    const handleClickDeleteOneContact = async (event, id) => {
        event.stopPropagation();
        await store.dispatch(setContactsToDelete([id]));
        store.dispatch(openAlertDeleteContactDialog(true));
    };
    const handleClickDeleteContacts = async () => {
        await store.dispatch(setContactsToDelete(rowSelectionModel));
        store.dispatch(openAlertDeleteContactDialog(true));
    };
    const [sortModel, setSortModel] = React.useState([
        {
            field: 'name',
            sort: 'asc'
        }
    ]);
    const columns = [
        {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            renderHeader: (params) => (
                <Tooltip
                    title={rowSelectionModel.length && rowSelectionModel.length !== contacts.length ? 'Deselect labels' : rowSelectionModel.length === contacts.length ? 'Deselect all labels' : 'Select all labels'}>
                    <div>
                        <GridHeaderCheckbox {...params} />
                    </div>
                </Tooltip>
            ),
        },
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 140,
            flex: 1,
            sortable: !isCheckedContacts,
            disableColumnMenu: isCheckedContacts,
            renderHeader: (params) => (
                isCheckedContacts ? <>
                    <Tooltip title='Copy contacts to labels'>
                        <IconButton
                            size="medium"
                            onClick={handleClickCopyContactsToLabel}
                        >
                            <SvgIcon>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17.63 5.84C17.27 5.33 16.67 5 16 5L5 5.01C3.9 5.01 3 5.9 3 7V17C3 18.1 3.9 18.99 5 18.99L16 19C16.67 19 17.27 18.67 17.63 18.16L22 12L17.63 5.84ZM16 17H5V7H16L19.55 12L16 17Z"
                                        fill="#3B7DED"/>
                                    <path
                                        d="M12 10.13C8.11 10.67 6.56 13.33 6 16C7.39 14.13 9.22 13.28 12 13.28V15.47L16 11.73L12 8V10.13Z"
                                        fill="#3B7DED"/>
                                </svg>
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Remove contacts from label'>
                        <span>
                            <IconButton
                                disabled={isDisabledDeleteLabel(currentGroup)}
                                size="medium"
                                onClick={handleClickRemoveContactsFromGroup}
                            >
                                <LabelOffOutlined
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        flexShrink: 0,
                                    }}
                                />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        title='Delete contacts'
                    >
                        <span>
                            <IconButton
                                onClick={handleClickDeleteContacts}
                                disabled={!currentGroup?.is_user_owner}
                            >
                                <DeleteOutlineOutlined
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        flexShrink: 0,
                                    }}
                                />
                            </IconButton>
                        </span>
                    </Tooltip>
                </> : <span>
                    {params.colDef.headerName}
                </span>

            ),
            valueGetter: (params) => {
                return params.value?.trim() || params.row.email;
            },
            renderCell: params => (
                <>
                    <Box mr={1}><Avatar src={params.row.photo}/></Box>
                    {params.value}
                </>
            ),
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 128,
            flex: 1,
            sortable: !isCheckedContacts,
            disableColumnMenu: isCheckedContacts,
            renderHeader: (params) => (
                isCheckedContacts ? <></> : <span>
                    {params.colDef.headerName}
                </span>
            ),
        },
        {
            field: 'phone',
            headerName: 'Phone number',
            minWidth: 128,
            flex: 1,
            sortable: !isCheckedContacts,
            disableColumnMenu: isCheckedContacts,
            renderHeader: (params) => (
                isCheckedContacts ? <></> : <span>
                    {params.colDef.headerName}
                </span>
            ),
        },
        {
            field: 'memberShips',
            headerName: 'Labels',
            minWidth: 353.3,
            sortable: !isCheckedContacts,
            disableColumnMenu: isCheckedContacts,
            renderHeader: (params) => (
                isCheckedContacts ? <></> : <span>
                    {params.colDef.headerName}
                </span>
            ),
            renderCell: (params) => {
                const filteredGroups = [];
                groups.forEach((group) => {
                    const membership = params.row.memberships.find((item) => {
                        return item.contactGroupMembership && item.contactGroupMembership.contactGroupResourceName === group.resource
                    });
                    if (membership) {
                        filteredGroups.push(group);
                    }
                })
                return (
                    <>
                        {
                            filteredGroups.reduce((acc, item, index) => {
                                if (index > 2) return acc;
                                if (index === 2) {
                                    return (
                                        [...acc, <Chip key={index} sx={{marginRight: '8px'}}
                                                       label={<Typography
                                                           variant={'label'}>+{filteredGroups.length - 2}</Typography>}
                                                       size="medium"
                                                       variant={'filled'} color={'default'}/>]
                                    )
                                }
                                return (
                                    [...acc, <Chip key={index}
                                                   sx={{
                                                       marginRight: '8px',
                                                       maxWidth: '200px',
                                                   }}
                                                   label={<Typography noWrap variant={'label'}>{item.name}</Typography>}
                                                   size="medium"
                                                   variant={'filled'} color={'default'}/>]
                                )
                            }, [])
                        }
                    </>
                );
            }
        },
        {
            field: 'action',
            headerName: '',
            width: 168,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: (params) => (
                isCheckedContacts ? <>
                    <Box
                        display="flex"
                        justifyContent="center"
                        sx={{width: '576px'}}>
                        <Typography sx={{
                            color: '#3B7DED',
                            fontFamily: 'Open Sans',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '24px',
                        }}>Selected: {rowSelectionModel.length}</Typography>
                    </Box></> : <span>
                    {params.colDef.headerName}
                </span>
            ),
            renderCell: params => {
                return (
                    params.id === rowHover && <>
                        {checkRole(RolesConstants.ROLE_EDIT, currentGroup) && <Tooltip
                            placement="bottom"
                            title='Edit contact'
                        >
                            <IconButton
                                onClick={(event) => handleClickEditContact(event, params.id)}
                            >
                                <ModeEditOutlined
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        flexShrink: 0,
                                        color: 'rgba(0, 0, 0, 0.54)',
                                    }}
                                />
                            </IconButton>
                        </Tooltip>}
                        {currentGroup?.is_user_owner && <Tooltip
                            placement="bottom"
                            title='Delete contact'
                        >
                            <IconButton onClick={(event) => handleClickDeleteOneContact(event, params.id)}>
                                <DeleteOutlineOutlined
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        flexShrink: 0,
                                        color: 'rgba(0, 0, 0, 0.54)',
                                    }}
                                />
                            </IconButton>
                        </Tooltip>}
                    </>
                )
            }
        },
    ];
    const getContactsByFilter = (contacts, filter) => {
        if (!filter || Object.keys(filter).length === 0) return contacts;
        return contacts.filter(item => item.id === filter.id);
    }
    let rows = selectedContacts || [];
    rows = getContactsByFilter(rows, searchContactsFilter);
    const extraHeight =
        parseInt(
            document.documentElement.style.getPropertyValue('--extra-height'),
            10
        ) || 0;
    const gridHeight = document.documentElement.clientHeight - (238 + extraHeight);
    let rowsCount = Math.floor(gridHeight / 72);
    if (rowsCount < 1) rowsCount = 1;

    const [rowHover, setRowHover] = React.useState('');
    const handleOnMouseEnter = (event) => {
        setRowHover(event.currentTarget.dataset.id);
    };
    const handleOnMouseLeave = () => {
        setRowHover(null);
    };
    const [dataGridKey, setDataGridKey] = useState(0);
    const updateDataGridKey = useCallback(() => {
        if (dataGridKey !== window.innerHeight) {
            setDataGridKey(window.innerHeight)
        }
    }, [dataGridKey]);
    useLayoutEffect(() => {
        window.addEventListener('resize', updateDataGridKey)
        return () => window.removeEventListener('resize', updateDataGridKey)
    }, [updateDataGridKey]);
    return (
        <>
            <div style={{
                height: domainInstallAlert ? `calc(100vh - ${topHeight}px - 48px)` : `calc(100vh - ${topHeight}px - 5px)`,
                width: '100%',
                // display: "table",
                // tableLayout: "fixed",
            }}>
                {statusFirstLoad === 'loading' ? <SkeletonContacts/> :
                    <StyledDataGrid
                        key={dataGridKey}
                        rows={rows}
                        columns={columns}
                        columnHeaderHeight={56}
                        rowHeight={72}
                        autoPageSize
                        checkboxSelection
                        disableColumnSelector
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            store.dispatch(setRowSelectionModel(newRowSelectionModel));
                        }}
                        rowSelectionModel={rowSelectionModel}
                        sortModel={sortModel}
                        onSortModelChange={(model) => setSortModel(model)}
                        slotProps={{
                            row: {
                                onMouseEnter: handleOnMouseEnter,
                                onMouseLeave: handleOnMouseLeave,
                            },
                        }}
                        components={{NoRowsOverlay}}
                    />
                }
            </div>
            <AddContactsToLabelsDialog rowSelectionModel={rowSelectionModel}/>
        </>
    );
}

const NoRowsOverlay = () => {
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    return (
        <Stack height="100%" alignItems="center" justifyContent="center" spacing={3}>
            <SvgIcon sx={{width: 48, height: 48}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48" fill="none">
                    <path
                        d="M20.5 24C24.92 24 28.5 20.42 28.5 16C28.5 11.58 24.92 8 20.5 8C16.08 8 12.5 11.58 12.5 16C12.5 20.42 16.08 24 20.5 24ZM20.5 12C22.7 12 24.5 13.8 24.5 16C24.5 18.2 22.7 20 20.5 20C18.3 20 16.5 18.2 16.5 16C16.5 13.8 18.3 12 20.5 12Z"
                        fill="black" fillOpacity="0.54"/>
                    <path
                        d="M8.5 36C8.94 34.56 15.12 32 20.5 32C20.5 30.6 20.76 29.26 21.2 28.02C15.74 27.82 4.5 30.54 4.5 36V40H23.58C22.54 38.84 21.72 37.5 21.2 36H8.5Z"
                        fill="black" fillOpacity="0.54"/>
                    <path
                        d="M29.32 40L34.5 34.82L39.68 40L42.5 37.18L37.32 32L42.5 26.82L39.68 24L34.5 29.18L29.32 24L26.5 26.82L31.68 32L26.5 37.18L29.32 40Z"
                        fill="black" fillOpacity="0.54"/>
                </svg>
            </SvgIcon>
            <Box
                sx={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '8px',
                    width: 300,
                }}
            >
                <Typography
                    sx={{
                        color: 'rgba(60, 64, 67, 1)',
                        fontFamily: 'Open Sans',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '24px',
                        letterSpacing: '-0.72px',
                    }}
                >
                    There are no contacts in this label yet
                </Typography>
                <Typography
                    sx={{
                        color: 'rgba(0, 0, 0, 0.60)',
                        fontFamily: 'Open Sans',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        letterSpacing: '-0.56px',
                        textAlign: 'center',
                    }}
                >
                    Add contacts and they will be displayed there
                </Typography>
            </Box>
            {checkRole(RolesConstants.ROLE_EDIT, currentGroup) && <AddContactsMenu buttonType={'button'}/>}
        </Stack>
    );
}
