import {styled} from "@mui/material/styles";
import {DataGrid} from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    border: 0,
    color:
        theme.palette.mode === "light"
            ? "rgba(0,0,0,.85)"
            : "rgba(255,255,255,0.85)",
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    "& .MuiDataGrid-columnsContainer": {
        backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d"
    },
    "& .MuiDataGrid-iconSeparator": {
        display: "none"
    },
    "& .MuiDataGrid-columnHeader": {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 14,
        fontFamily: "Roboto",
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0.17px',
        borderRight: `0px solid ${
            theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
        }`
    },
    "& .MuiDataGrid-cell": {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 14,
        fontFamily: "Roboto",
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.2px',
    },
    "& .MuiPaginationItem-root": {
        borderRadius: 0
    },
    borderRadius: 0,
    '& .MuiDataGrid-columnSeparator': {
        display: 'none'
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
        outline: 'none'
    },
    '& .MuiBadge-badge': {
        display: 'none'
    }
}));

