import {Box, Button, Container, Stack, SvgIcon, Typography} from "@mui/material";
import * as React from "react";
import {useSelector} from "react-redux";

export default function Congratulations() {
    const domainInstallAlert = useSelector((state) => state.user.domainInstallAlert);
    return (
        <Container disableGutters sx={{width: '400px'}}>
            <Stack
                spacing={1}
                sx={{
                    display: 'flex',
                    width: '100%',
                    minHeight: domainInstallAlert ? 'calc(100vh - 64px - 48px)' : 'calc(100vh - 64px)',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <SvgIcon sx={{width: 96, height: 96, marginBottom: 2}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
                        <rect width="96" height="96" rx="48" fill="#D6EFD2"/>
                        <path
                            d="M47.968 16C30.304 16 16 30.336 16 48C16 65.664 30.304 80 47.968 80C65.664 80 80 65.664 80 48C80 30.336 65.664 16 47.968 16Z"
                            fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M16 48C16 30.336 30.336 16 48 16C65.664 16 80 30.336 80 48C80 65.664 65.664 80 48 80C30.336 80 16 65.664 16 48ZM41.5999 54.9439L62.6879 33.8559L67.1999 38.3999L41.5999 63.9999L28.7999 51.1999L33.3119 46.6879L41.5999 54.9439Z"
                              fill="#3BA22B"/>
                    </svg>
                </SvgIcon>
                <Typography
                    sx={{
                        textAlign: 'center',
                        color: 'rgba(60, 64, 67, 1)',
                        fontFamily: `"Open Sans"`,
                        fontSize: 28,
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '40px',
                        letterSpacing: '-0.56px',
                    }}>Congratulations!<br/>You’re eligible for a new trial</Typography>
                <Typography
                    sx={{
                        padding: '0 20px 0 20px',
                        textAlign: 'center',
                        color: 'rgba(0, 0, 0, 0.6)',
                        fontFamily: `"Open Sans"`,
                        fontSize: 17,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: '-0.34px',
                    }}>You can access your account for an additional 2-week free trial.</Typography>
                <Box>
                    <Button
                        variant='contained'
                        size="medium"
                        sx={{
                            marginTop: 2,
                        }}
                    >
                        <Typography variant={'button'} sx={{textTransform: "none"}}>Access my new free
                            trial</Typography>
                    </Button>
                </Box>
            </Stack>
        </Container>

    )
}
